import client from "@/utils/api/client";
import useFetch from "@/hooks/useFetch";
import { ProjectDetailResponse } from "@/web-client/api";
import { useRouter } from "next/router";

interface ProjectHookResponse {
  project?: ProjectDetailResponse;
  setProject(project?: ProjectDetailResponse, shouldRevalidate?: boolean);
  error?: Error;
}

const useProject = (
  id: number,
  initialProject?: ProjectDetailResponse,
): ProjectHookResponse => {
  const {
    isReady,
    query: { token },
  } = useRouter();
  const {
    data: project,
    error,
    mutate,
  } = useFetch<ProjectDetailResponse>(
    isReady ? `/projects/${id}` : null,
    async () => {
      const { data } = await client.projectsIdGet({
        id,
        token: token as string,
      });

      return data;
    },
    initialProject,
    { waitForAuth: true },
  );

  return { project, error, setProject: mutate };
};

export default useProject;
