import { FC, PropsWithChildren } from "react";
import client from "@/utils/api/client";
import {
  LikeKind,
  ListItemKind,
  PostResponse,
  ProjectCreditValue,
  ProjectDetailResponse,
  ProjectResponse,
} from "@/web-client/api";
import PostItem from "@/features/post/components/PostItem";
import { CreditItem } from "@/components/CreditItem";
import Link from "next/link";
import { useCallback, useMemo } from "react";
import RenderIfAuth from "@/components/RenderIfAuth";
import useDisclosure from "@/hooks/useDisclosure";
import { useRouter } from "next/router";
import { ImageSize } from "@/consts/ImageSize";
import dynamic from "next/dynamic";
import { showSnackbar } from "@/stores/snackbar";
import ProjectItem from "@/features/project/components/ProjectItem";
import { useDispatch } from "react-redux";
import formatConstructionType from "@/utils/formatConstructionType";
import useAnalytics from "@/hooks/useAnalytics";
import ProjectItemList from "@/features/project/components/ProjectItemList";
import TeamIcon from "@/features/team/components/TeamIcon";
import Avatar, { AvatarSize } from "@/components/Avatar";
import useDevice from "@/hooks/useDevice";
import ChildrenWithSeparate from "@/components/ChildrenWithSeparate";
import UserIcon from "@/features/user/components/UserIcon";
import BorderList from "@/components/BorderList";
import OldIconButton from "@/components/OldIconButton";
import useClampItems from "@/hooks/useClampItems";
import usePageView from "@/hooks/usePageView";
import useFetch from "@/hooks/useFetch";
import ShareBtn from "@/components/ShareBtn";
import LikeBtn from "@/features/like/components/LikeBtn";
import AddListBtnWrapper from "@/features/list/components/AddListBtnWrapper";
import SimpleIconButton from "@/components/SimpleIconButton";
import useLike from "@/features/like/hooks/useLike";
import useArrayMutation from "@/hooks/useArrayMutation";
import { NextSeo } from "next-seo";
import publicPath from "@/utils/publicPath";
import useBtnRequiresIsDesigner from "@/features/user/hooks/useBtnRequiresDesigner";
import useIsMyTeam from "@/features/team/hooks/useIsMyTeam";
import { GMAP_API_KEY } from "@/config/env";
import Button from "@/components/Button";
import clsx from "clsx";
import Maybe from "@/components/Maybe";
import ProjectAttachmentItem from "@/features/project_attachment/components/ProjectAttachmentItem";
import IconEdit from "@/assets/imgs/svg/icon-edit.svg";
import IconAdd from "@/assets/imgs/svg/icon-add.svg";
import IconWeb from "@/assets/imgs/svg/icon-web.svg";
import IconView from "@/assets/imgs/svg/icon_remove_red_eye.svg";
import formatNumber from "@/utils/formatNumber";

const DialogCompleteCreatingProject = dynamic(
  () => import("@/features/project/components/DialogCompleteCreatingProject"),
);

const DialogPrivateShare = dynamic(() =>
  import("@/features/project").then((mod) => mod.DialogPrivateShare),
);

const MinDisplayPhotoNum = 3;

interface Props {
  project: ProjectDetailResponse;
  setProject?: (
    project?: ProjectDetailResponse,
    shouldRevalidate?: boolean,
  ) => void;
}

const ProjectShowTemplate: FC<Props> = ({
  project,
  setProject: mutateProject,
}) => {
  usePageView();
  const { isSp, isPc } = useDevice();
  const { event } = useAnalytics();
  const dispatch = useDispatch();
  const router = useRouter();
  const editable = useIsMyTeam(project.team.id, { skipCheckDesigner: true });

  const { isOpen: isCompletionOpen, closeHandler: closeCompletionHandler } =
    useDisclosure(router.query.complete !== undefined);

  const { data: otherProjects } = useFetch<ProjectResponse[]>(
    project ? `/projects/${project.id}/otherProjects` : null,
    async () => {
      const { data } = await client.teamsIdProjectsGet({
        id: project.team.id,
        excludeProjectId: project.id,
        limit: 3,
        page: 1,
      });

      return data.results;
    },
  );
  const { data: posts, mutate: setPosts } = useFetch<PostResponse[]>(
    project ? `/projects/${project.id}/posts` : null,
    async () => {
      const { data } = await client.projectsIdPostsGet({
        id: project.id,
        limit: 100,
        token: router.query.token as string,
      });

      return data.results;
    },
    undefined,
    { waitForAuth: true },
  );

  const displayCredit = useMemo<ProjectCreditValue[]>(() => {
    return project.credit
      ? project.credit.filter((c) => c.values.length > 0)
      : [];
  }, [project]);

  const googleMapUrl = useMemo<string>(() => {
    if (!project.location) return;

    return `https://www.google.com/maps/embed/v1/place?key=${GMAP_API_KEY}&q=${project.location.lat},${project.location.lng}`;
  }, [project]);

  const {
    items: clamppedPosts,
    open,
    isEnable,
  } = useClampItems<PostResponse>(
    posts || [],
    MinDisplayPhotoNum,
    isSp && (posts || []).length > MinDisplayPhotoNum,
  );

  const { likeHandler } = useLike<ProjectDetailResponse>(
    project,
    LikeKind.PROJECT,
    (updatedProject) => mutateProject && mutateProject(updatedProject, false),
  );

  const { update } = useArrayMutation<PostResponse>();

  const publish = useCallback(async () => {
    if (posts.length < 1) {
      alert(
        "プロジェクトを公開するには、1枚以上の写真を含める必要があります。",
      );

      return;
    }

    await client.projectsIdPublishPost({ id: project.id });
    project.is_published = true;
    mutateProject && mutateProject(project);

    event("publish_project", {
      item_id: `PJ_${project.id}`,
    });

    dispatch(
      showSnackbar({
        severity: "success",
        message: "プロジェクトを公開しました",
      }),
    );
  }, [posts, project, mutateProject, dispatch, event]);

  const { clickHandler: clickPublishBtnHandler } =
    useBtnRequiresIsDesigner(publish);

  const privateShareDisclosure = useDisclosure();
  const { clickHandler: clickPrivateShareBtnHandler } =
    useBtnRequiresIsDesigner(privateShareDisclosure.openHandler);

  return (
    <>
      <NextSeo
        title={project.title}
        description={project.description || `${project.title}のページです。`}
        openGraph={{
          images: [{ url: project.main_image?.urls?.original }],
          type: "article",
        }}
        canonical={publicPath(`/projects/${project.id}`)}
      />
      <div
        className={clsx(
          "grid",
          "w-full mx-auto pt-32 pb-120 gap-16 max-w-[1280px]",
          "laptop:py-80 laptop:gap-24 laptop:px-32",
        )}
      >
        <div className="grid gap-24 items-start px-16 laptop:px-0">
          <header className="grid gap-24">
            <div className="flex items-start justify-between space-x-12">
              <h1 className="text-xl laptop:text-2xl tracking-wider laptop:whitespace-pre-line">
                {project.title}
              </h1>
              <Maybe condition={editable}>
                <Button leftIcon={<IconEdit />} size="small" asChild>
                  <Link
                    href={`/projects/${project.id}/edit`}
                    aria-label="プロジェクトの編集"
                  >
                    編集
                  </Link>
                </Button>
              </Maybe>
            </div>
            <Maybe condition={isSp}>
              <TeamIcon team={project.team} size={AvatarSize.Small} />
            </Maybe>
          </header>

          <Maybe condition={!project.is_published}>
            <div
              className={clsx(
                "relative bg-white p-16 rounded-xs",
                "laptop:pl-24",
              )}
            >
              <div
                className={clsx(
                  "grid gap-8",
                  "laptop:grid-flow-col laptop:grid-cols-[1fr_auto] laptop:gap-24",
                )}
              >
                <div
                  className={clsx(
                    "grid items-center",
                    "laptop:grid-flow-col laptop:grid-cols-[auto_1fr] laptop:gap-24",
                  )}
                >
                  <p className="font-bold text-sm">未公開のプロジェクト</p>
                  <p className="text-sm">
                    このプロジェクトはまだ公開されていません。
                  </p>
                </div>
                <Maybe condition={editable}>
                  <div className="laptop:space-x-16 small:space-y-8">
                    <Button
                      onClick={clickPublishBtnHandler}
                      size="small"
                      className="small:w-full"
                    >
                      公開する
                    </Button>
                    <Button
                      color="outlined"
                      size="small"
                      className="small:w-full"
                      onClick={clickPrivateShareBtnHandler}
                    >
                      非公開で共有
                    </Button>
                  </div>
                </Maybe>
              </div>
            </div>
          </Maybe>
        </div>

        <div className="space-y-40 laptop:space-y-80">
          <main className="grid grid-cols-1 gap-36 laptop:grid-cols-[8fr_1px_3fr] laptop:gap-32 ">
            <div className="space-y-40 laptop:col-start-1 laptop:col-end-2 laptop:space-y-80">
              <div className="grid gap-24">
                <div className="space-y-12">
                  <div className="grid gap-4">
                    {clamppedPosts.map((post) => (
                      <PostItem
                        key={post.id}
                        post={post}
                        imageSize={ImageSize.Large}
                        mutate={(updated) =>
                          setPosts(update(posts, updated), false)
                        }
                        showButtons={project.is_published}
                      />
                    ))}
                  </div>

                  {isEnable && (
                    <div className="px-16">
                      <button
                        className="bg-black rounded-full text-center w-full text-white p-8 text-sm font-bold"
                        onClick={open}
                      >
                        全ての写真を表示
                      </button>
                    </div>
                  )}
                </div>

                <div
                  className={clsx(
                    "grid grid-cols-2 gap-16 px-16",
                    "laptop:grid-cols-4 laptop:px-0 laptop:gap-24",
                  )}
                >
                  {project.attachments.map((attachment, index) => (
                    <ProjectAttachmentItem
                      key={attachment.id}
                      attachments={project.attachments}
                      index={index}
                    />
                  ))}
                </div>
              </div>

              <p className="text-reading whitespace-pre-line px-16 text-justify">
                {project.description}
              </p>
            </div>

            {isPc && (
              <div className="w-full bg-gray-200 laptop:col-start-2 laptop:col-end-3" />
            )}

            <div className="space-y-32 laptop:col-start-3 laptop:col-end-4 px-16 laptop:px-0">
              {project.is_published && (
                <div className="flex gap-8">
                  <div className="flex-1 grid">
                    <LikeBtn
                      hasLiked={project.has_liked}
                      likesCount={project.likes_count}
                      onClick={likeHandler}
                    />
                  </div>

                  <div className="flex-1 grid">
                    <div
                      className={clsx(
                        "flex-1",
                        "relative px-12 py-4 truncate",
                        "flex items-center justify-between",
                        "text-gray-400 bg-white rounded-full overflow-hidden",
                        "border border-transparent",
                      )}
                      arua-label="閲覧数"
                      title="閲覧数"
                    >
                      <span className="fill-black">
                        <IconView width={16} height={16} />
                      </span>

                      <span className="text-sm text-primary">
                        {formatNumber(project.page_views_count)}
                      </span>
                    </div>
                  </div>

                  <AddListBtnWrapper
                    selectedItem={project}
                    kind={ListItemKind.PROJECT}
                  >
                    <SimpleIconButton>
                      <IconAdd width={16} height={16} />
                    </SimpleIconButton>
                  </AddListBtnWrapper>

                  <ShareBtn />
                </div>
              )}

              <DataBox label="チーム">
                <TeamIcon
                  team={project.team}
                  size={isSp ? AvatarSize.Large : AvatarSize.XLarge}
                />
              </DataBox>

              <DataBox label={"メンバー"}>
                <ul className="grid gap-8">
                  {project.users.map((member) => (
                    <li key={member.id}>
                      <UserIcon
                        user={member}
                        hasName={true}
                        size={AvatarSize.Normal}
                      />
                    </li>
                  ))}
                </ul>
              </DataBox>

              {displayCredit.length > 0 && (
                <DataBox label={"クレジット"}>
                  <BorderList borderStyle={"dotted"}>
                    {displayCredit.map((item, i) => {
                      return (
                        <li key={i}>
                          <CreditItem
                            label={item.label}
                            content={item.values.join(" / ")}
                          />
                        </li>
                      );
                    })}
                  </BorderList>
                </DataBox>
              )}

              <DataBox label={"データ"}>
                <BorderList borderStyle={"dotted"}>
                  <li>
                    <CreditItem
                      label={"ビルディングタイプ"}
                      content={
                        <Link
                          href={`/architectures/projects/search?building_type_id=${project.building_type.id}`}
                          className="underline"
                        >
                          {project.building_type.name}
                        </Link>
                      }
                    />
                  </li>

                  {project.structure_types.length > 0 && (
                    <li>
                      <CreditItem
                        label={"構造"}
                        content={project.structure_types.map(
                          (structure_type, index) => {
                            return (
                              <Link
                                key={index}
                                href={`/architectures/projects/search?structure_type_id=${structure_type.id}`}
                                className="underline block text-right"
                              >
                                {structure_type.label}
                              </Link>
                            );
                          },
                        )}
                      />
                    </li>
                  )}

                  {project.construction_type && (
                    <li>
                      <CreditItem
                        label={"工事種別"}
                        content={
                          <Link
                            href={`/architectures/projects/search?construction_type=${project.construction_type}`}
                            className="underline"
                          >
                            {formatConstructionType(project.construction_type)}
                          </Link>
                        }
                      />
                    </li>
                  )}

                  {project.floor_space && (
                    <li>
                      <CreditItem
                        label={"延べ床面積"}
                        content={`${project.floor_space}㎡`}
                      />
                    </li>
                  )}

                  {project.completion && (
                    <li>
                      <CreditItem
                        label={"竣工"}
                        content={`${project.completion}`}
                      />
                    </li>
                  )}

                  {googleMapUrl && (
                    <li>
                      <iframe
                        style={{ border: 0 }}
                        referrerPolicy="no-referrer-when-downgrade"
                        src={googleMapUrl}
                        className="w-full h-[300px]"
                        allowFullScreen
                      />
                    </li>
                  )}
                </BorderList>
              </DataBox>

              {project.tags && project.tags.length > 0 && (
                <DataBox label="タグ">
                  <ul className="[&>*]:inline-block [&>*]:mr-4 [&>*]:mb-4">
                    {project.tags.map((value) => (
                      <li key={value}>
                        <Link
                          href={`/search/posts?keyword=${value}`}
                          prefetch={false}
                          className="block bg-white border border-primary px-12 py-6 rounded-full text-xs hover:bg-gray-100"
                        >
                          {value}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </DataBox>
              )}

              {project.magazine_slug && (
                <DataBox label="メディア">
                  <a
                    href={`https://mag.tecture.jp/project/${project.magazine_slug}`}
                    target="_blank"
                  >
                    <OldIconButton
                      frontIcon={<IconWeb width={16} height={16} />}
                      label={
                        project.magazine_link_label || "TECTURE MAGの特集記事"
                      }
                    />
                  </a>
                </DataBox>
              )}
            </div>
          </main>

          {otherProjects && otherProjects.length > 0 && (
            <aside className="mx-auto w-full space-y-24 px-16 laptop:space-y-48 laptop:px-0">
              <div className="flex flex-col items-center">
                <ChildrenWithSeparate>
                  <Link href={`/teams/${project.team.id}/projects`}>
                    <Avatar
                      size={isSp ? AvatarSize.Normal : AvatarSize.Large}
                      src={
                        project.team.logo
                          ? project.team.logo.urls.small
                          : "/imgs/default_team_icon.png"
                      }
                      alt={project.team.name}
                    />
                  </Link>
                </ChildrenWithSeparate>

                <p className="font-bold">{project.team.name}</p>
              </div>

              <ProjectItemList>
                {otherProjects.map((project) => (
                  <li key={project.id}>
                    <ProjectItem project={project} hasTeam={false} />
                  </li>
                ))}
              </ProjectItemList>
            </aside>
          )}
        </div>
      </div>

      <RenderIfAuth>
        <DialogCompleteCreatingProject
          projectId={project.id}
          isOpen={isCompletionOpen}
          close={() => {
            closeCompletionHandler();
            router.push({ pathname: `/projects/${project.id}` }, undefined, {
              shallow: true,
            });
          }}
        />

        <DialogPrivateShare
          isOpen={privateShareDisclosure.isOpen}
          close={privateShareDisclosure.closeHandler}
          projectId={project.id}
        />
      </RenderIfAuth>
    </>
  );
};

export default ProjectShowTemplate;

type DataBoxProps = {
  label: string;
};

const DataBox: FC<PropsWithChildren<DataBoxProps>> = ({
  label,
  children,
}): JSX.Element => {
  return (
    <div className="space-y-8">
      <p className="text-2xs font-bold">{label}</p>
      <div>{children}</div>
    </div>
  );
};
