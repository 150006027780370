import { FC, CSSProperties } from "react";
import clsx from "clsx";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { RedirectReason } from "@/domain/values/RedirectReason";
import { getReasonText } from "@/utils/getReasonText";
import formatNumber from "@/utils/formatNumber";
import IconLikeFill from "@/assets/imgs/svg/icon-like-fill.svg";

type Props = {
  hasLiked: boolean;
  onClick: VoidFunction;
  likesCount?: number;
  style?: CSSProperties;
  hideCount?: boolean;
};

const LikeBtn: FC<Props> = ({
  hasLiked,
  onClick,
  likesCount,
  style,
  hideCount = false,
}): JSX.Element => {
  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    action: onClick,
    message: getReasonText(RedirectReason.Like),
  });

  return (
    <>
      <button
        style={style}
        className={clsx(
          "relative rounded-full px-12 py-4 overflow-hidden truncate border border-primary",
          hasLiked ? "bg-like text-white" : "text-gray-400 bg-white",
          "hover:brightness-110",
        )}
        onClick={executeAction}
      >
        <span className="flex gap-8 justify-between items-center text-sm">
          <span
            className={clsx(
              "flex items-center gap-4",
              hasLiked ? "fill-white" : "fill-gray-300",
            )}
          >
            <IconLikeFill width={16} height={16} />
          </span>
          {!hideCount && <span>{formatNumber(likesCount)}</span>}
        </span>
      </button>
      {SignInModalComponent}
    </>
  );
};

export default LikeBtn;
