import { useCallback, useMemo, useState } from "react";
import { useRouter } from "next/router";
import IconShareFill from "@/assets/imgs/svg/icon-share-fill.svg";
import SimpleIconButton from "@/components/SimpleIconButton";
import { PUBLIC_URL } from "@/config/env";

const ShareBtn = (): JSX.Element => {
  const router = useRouter();
  const [display, setDispaly] = useState(false);

  const shareUrl = useMemo<string>(() => {
    return `${PUBLIC_URL}${router.asPath}`;
  }, [router.asPath]);

  const clickHandler = () => setDispaly((prev) => !prev);
  const closeHandler = () => setDispaly(false);
  const twitterHandler = useCallback(() => {
    window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`, "_blank");
  }, [shareUrl]);

  const facebookHandler = useCallback(() => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      "_blank",
    );
  }, [shareUrl]);

  return (
    <div className="relative">
      <button onClick={clickHandler}>
        <SimpleIconButton>
          <IconShareFill width={16} height={16} />
        </SimpleIconButton>
      </button>

      {display && (
        <div
          className="shadow-default z-modal absolute right-0 bg-white rounded-md py-16 px-32 text-center space-y-32"
          style={{ top: "120%" }}
        >
          <div className="space-y-24">
            <p className="font-bold">ページをシェア</p>
            <ul className="flex justify-center space-x-24 text-xs">
              <li>
                <button onClick={facebookHandler} className="space-y-4">
                  <div className="rounded-full w-[56px] h-[56px] flex items-center justify-center bg-sns-facebook">
                    <i className="icon icon-Facebook text-white text-2xl" />
                  </div>
                  <span className="inline-block">Facebook</span>
                </button>
              </li>
              <li>
                <button onClick={twitterHandler} className="space-y-4">
                  <div className="rounded-full w-[56px] h-[56px] flex items-center justify-center bg-sns-twitter">
                    <i className="icon icon-Twitter text-white text-2xl" />
                  </div>
                  <span className="inline-block">Twitter</span>
                </button>
              </li>
            </ul>
          </div>
          <button onClick={closeHandler} className="text-xs text-secondary">
            閉じる
          </button>
        </div>
      )}
    </div>
  );
};
export default ShareBtn;
